<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <b-img
          :src="require('@/assets/images/logo/logo.svg')"
          alt="Surfe logo"
          style="max-width:150px"
        />
      </b-link>

      <!-- Feedback-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          v-if="!sent"
          sm="8"
          md="6"
          lg="8"
          xl="6"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="mb-2 font-weight-bolder font-mono"
          >
            We regret to see you go!
          </b-card-title>

          <b-card-text class="mb-7 text-muted">
            Would you mind briefly telling us about why you're leaving?
          </b-card-text>

          <b-form-group class="mb-7">
            <b-form-checkbox-group
              v-model="reasons"
              :options="options"
              stacked
            />
          </b-form-group>

          <transition name="fade">
            <div v-if="isRoadMapLinkVisible">
              <b-card>
                <b-card-text>
                  Have you taken a look at our Roadmap? Many features are on the way to you!
                </b-card-text>
                <a
                  href="https://roadmap.surfe.com/"
                  class="card-link font-weight-bold"
                  target="_blank"
                >
                  Surfe Roadmap
                </a>
              </b-card>
            </div>
          </transition>

          <b-form-textarea
            v-model="comment"
            placeholder="Want to say something else?"
            rows="2"
          />

          <b-button
            class="mt-3 px-3"
            variant="primary"
            block
            :disabled="sendDisabled"
            @click="send"
          >
            Send
          </b-button>
        </b-col>

        <b-col v-else>
          Thank you for your feedback!
        </b-col>
      </b-col>

      <!-- Cover -->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center justify-content-center p-5 bg-primary bg-lighten-5"
      >
        <div
          class="d-flex position-relative"
          style="width: 433px; height: 340px;"
        >
          <b-img
            fluid
            :src="require('@/assets/images/wave-orange.svg')"
            alt="Uninstall illustration"
            class="position-absolute position-left-0 position-right-0"
            style="top: 67.15%; bottom: 3.41%;"
          />
          <b-img
            fluid
            :src="require('@/assets/images/uninstall-illustration.png')"
            :srcset="require('@/assets/images/uninstall-illustration.png') + ' 1x, ' + require('@/assets/images/uninstall-illustration@2x.png') + ' 2x'"
            alt="Uninstall illustration"
            class="position-absolute"
            style="left: 3.23%; right: 3.23%; top: 14.71%; bottom: 11.76%;"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BFormTextarea, BCardText, BCardTitle, BImg, BButton, BFormGroup, BFormCheckboxGroup, BCard,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { trackEvent } from '@core/utils/tracking'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormTextarea,
    BCardText,
    BCardTitle,
    BImg,
    BCard,
    BButton,
    BFormGroup,
    BFormCheckboxGroup,
  },
  data() {
    return {
      options: [
        { text: 'Too expensive for my usage', value: 'Too expensive' },
        { text: 'Does not answer my needs', value: 'Not answer my needs' },
        { text: 'Not enough features', value: 'Not enough features' },
        { text: 'Too many bugs/issues', value: 'Too many bugs/issues' },
        { text: 'Switching to another product', value: 'Switching to another product' },
        { text: 'I will come back!', value: 'I will come back' },
      ],
      reasons: [],
      comment: '',
      sent: false,
    }
  },
  computed: {
    sendDisabled() {
      return this.reasons.length === 0 && this.comment.length === 0
    },
    isRoadMapLinkVisible() {
      if (this.reasons.length === 0) return false
      return this.reasons.includes('Not answer my needs') || this.reasons.includes('Not enough features')
    },
  },
  methods: {
    async send() {
      try {
        const iWillComeBack = this.reasons.length === 1 && this.reasons[0] === 'I will come back'
        if (!iWillComeBack) {
          await this.$store.dispatch('api/uninstallEvent', {
            reasons: this.reasons.join(', '),
            comment: this.comment,
            ...this.$route.query,
          })
          await trackEvent({
            type: 'dashboard-uninstall_form-sent',
            props: {
              email: this.$store.state.api.account.email || this.$route.query.email,
              reasons: this.reasons.join(', '),
              comment: this.comment,
            },
          })
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Feedback sent successfully',
            icon: 'SendIcon',
            variant: 'success',
          },
        })
        this.sent = true
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        throw error
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.custom-checkbox {
  margin: .8em 0;
}
</style>
